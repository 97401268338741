<template>
	<div>
		<b-card
			v-if="!loading"
			class="mb-2 tree-card">
			<div class="row">
				<!-- Search component and buttons-->
				<template
					v-if="$route.name !== 'DistributorsBinaryTree'">
					<div class="col-12 col-md-9">
						<div
							id="showSearch"
							class="row">
							<div class="col-12">
								<search
									v-model="selectedUser"
									:endpoint="endpoint"
									:empty-result-message="translate('data_not_found')"
									:default-params="{role:'distributor', tree: admin.includes($user.details().type) ? 'binary' : 'unilevel'}"
									:placeholder="'autocomplete_placeholder'"
									:init-value="getLoadedUser"
									q="label"
									return-key="id" />
							</div>
						</div>
					</div>
					<div
						:class="['xs','sm'].includes(windowWidth) ? 'mt-1 text-left' : 'text-right pl-0 mb-2'"
						class="col-12 col-md">
						<div :class="['xs','sm'].includes(windowWidth) ? 'row w-100 mx-auto mt-1' : ''">
							<b-button
								variant="primary"
								class="col-custom"
								@click="changeUser(selectedUser)">
								{{ translate('search') }}
							</b-button>
							<b-button
								variant="secondary"
								class="col-custom"
								:class="['xs','sm'].includes(windowWidth) ? 'mr-0 ml-auto' : 'ml-1'"
								@click="changeUser()">
								{{ translate('clear') }}
							</b-button>
						</div>
						<hr
							v-if="['xs','sm'].includes(windowWidth)"
							class="my-3"
							style="border-top: 2px solid rgba(29, 27, 27, 0.1);">
					</div>
				</template>
				<!-- Account types -->
				<div
					:class="['xs','sm'].includes(windowWidth) ? 'px-1' : 'd-flex align-items-center'"
					class="col-6 text-left bin-tree-icons">
					<span
						v-for="account in accountStatus"
						:key="account.statusCode"
						:class="['xs','sm'].includes(windowWidth) ? 'col-12' : ''"
						class="d-inline-block">
						<div
							v-if="admin.includes($user.details().type) || distributorHiddenStatuses[account.statusCode] === undefined"
							class="mr-4">
							<template v-if="account.separator === true">
								<span class="d-none d-sm-block ">|</span>
							</template>
							<i
								v-else
								:class="`${ account.icon } ${account.color}`"
								class="mr-1" /> {{ translate(account.statusCode) }}
						</div>
					</span>
					<!-- <span class="mr-2 d-inline-block mr-4">|</span>
					<span class="mr-2 d-inline-block">
						<i class="text-warning fas fa-exclamation-triangle mr-1" /> {{ translate('autoship_failed') }}
					</span> -->
				</div>
				<!-- View selector -->
				<div
					:class="['xs','sm'].includes(windowWidth) ? 'text-center' : 'text-right'"
					class="col-6 col-md">
					<span
						:class="['xs','sm'].includes(windowWidth) ? 'd-none' : ''"
						class="font-weight-bold">
						{{ translate('view') }}:
					</span>
					<b-button
						:variant="!isGraphic ? 'primary' : 'outline-primary'"
						:class="['xs','sm'].includes(windowWidth) ? 'w-100 rounded-0' : 'border-round-left py-1'"
						@click="handleTreeType('binary')">
						{{ translate('standard') }}
					</b-button>
					<b-button
						:variant="isGraphic ? 'primary' : 'outline-primary'"
						:class="['xs','sm'].includes(windowWidth) ? 'w-100 rounded-0' : 'border-round-right py-1'"
						@click="handleTreeType('graphic')">
						{{ translate('complex') }}
					</b-button>
				</div>
			</div>
			<!-- Top and Parent -->
			<b-row
				v-if="!isCountryCorporate"
				:class="{'mt-2' : ['xs','sm'].includes(windowWidth)}">
				<b-col class="text-center">
					<b-button
						v-if="tree.parent_id && $route.name !== 'DistributorsBinaryTree'"
						size="sm"
						variant="primary mx-1"
						@click="changeUser()">
						{{ translate('top') }}
					</b-button>
					<b-button
						v-if="tree.parent_id"
						size="sm"
						variant="primary mx-1"
						@click="changeUser(tree.parent_id)">
						{{ translate('parent') }}
					</b-button>
				</b-col>
			</b-row>
			<!-- Tree -->
			<b-row
				v-if="!isGraphic && hasData"
				class="mt-2">
				<b-col
					v-if="!['xs','sm'].includes(windowWidth)"
					class="col-auto d-flex align-items-end pr-0">
					<b-button
						v-b-tooltip.hover
						:disabled="hasOuterNodeLeft"
						:title="translate('outer_left')"
						size="sm"
						variant="primary"
						class="float-left"
						@click="changeUser(outerNodes.left_outer_distributor)">
						<span class="fas fa-arrow-alt-circle-down" />
					</b-button>
				</b-col>
				<b-col class="text-center">
					<div
						:class="{'bg-secondary': activeClass, 'pointer': hasData}"
						class="border tree-item py-2 rounded-top w-100 h-auto"
						@mouseenter="setItemActive">
						<b-row
							class="w-100 h-100 m-0 d-flex align-items-center justify-content-center">
							<b-col
								class="text-right ml-auto pr-0 text-center"
								cols="2">
								<span class="font-weight-bold h2 indicator-color">{{ translate('left_side') }}</span>
							</b-col>
							<b-col cols="4">
								<strong class="d-block text-gray-dark text-truncate">
									<i
										v-if="!(tree.is_spillover || tree.is_unauthorized)"
										:class="findColor(tree.status.toLowerCase())" />
									<template v-if="!!tree.username">
										{{ tree.username }}
									</template>
									<template v-if="tree.is_spillover">
										{{ translate('spillover').toUpperCase() }}
									</template>
									<template v-if="tree.is_unauthorized">
										{{ translate('unauthorized').toUpperCase() }}
									</template>
									<i
										v-if="tree.autoship_failed && !(tree.is_spillover || tree.is_unauthorized)"
										:class="`${ autoshipFailed.icon } ${ autoshipFailed.color }`"
										class="mr-1" />
									<span
										v-if="tree.has_ticket && tree.has_enabled_ticket"
										v-b-tooltip.hover
										:title="translate('has_purchased_ticket')"
										style="vertical-align: text-top;margin-left:5px;">
										<i class="fa fa-ticket primary-color" />
									</span>
									<span
										v-if="tree.has_ticket && !tree.has_enabled_ticket"
										v-b-tooltip.hover
										:title="translate('has_pending_ticket')"
										style="vertical-align: text-top;margin-left:5px;">
										<i class="fa fa-ticket text-muted" />
									</span>
								</strong>
								({{ tree.is_spillover || tree.is_unauthorized ? translate('NA') : tree.distributor_id }})
							</b-col>
							<b-col
								class="text-left mr-auto pl-0 text-center"
								cols="2">
								<span class="font-weight-bold h2 indicator-color">{{ translate('right_side') }}</span>
							</b-col>
						</b-row>
					</div>
					<div class="tree-level">
						<child-binary
							:active-user="activeUser"
							:tree="childItem('l')"
							:deep="childRows-1"
							placement="left"
							@registerInPosition="registerInPosition" />
						<child-binary
							:active-user="activeUser"
							:tree="childItem('r')"
							:deep="childRows-1"
							placement="right"
							@registerInPosition="registerInPosition" />
					</div>
				</b-col>
				<b-col
					v-if="!['xs','sm'].includes(windowWidth)"
					class="col-auto d-flex align-items-end pl-0">
					<b-button
						v-b-tooltip.hover
						:disabled="hasOuterNodeRight"
						:title="translate('outer_right')"
						size="sm"
						variant="primary"
						class="float-right"
						@click="changeUser(outerNodes.right_outer_distributor)">
						<span class="fas fa-arrow-alt-circle-down" />
					</b-button>
				</b-col>
			</b-row>
			<!-- Graphic Tree -->
			<graphic-tree
				v-if="isGraphic && hasData"
				:tree="tree"
				:deep="childRows-1"
				placement="right"
				class="mt-3"
				@registerInPosition="registerInPosition" />
			<!-- Nodes -->
			<b-row
				v-if="(['xs','sm'].includes(windowWidth) || isGraphic) && hasData"
				class="mt-1">
				<b-col>
					<b-button
						v-b-tooltip.hover
						:disabled="hasOuterNodeLeft"
						:title="translate('outer_left')"
						size="sm"
						variant="primary"
						class="float-left"
						@click="changeUser(outerNodes.left_outer_distributor)">
						<span class="fas fa-arrow-alt-circle-down" />
					</b-button>
				</b-col>
				<b-col>
					<b-button
						v-b-tooltip.hover
						:disabled="hasOuterNodeRight"
						:title="translate('outer_right')"
						size="sm"
						variant="primary"
						class="float-right"
						@click="changeUser(outerNodes.right_outer_distributor)">
						<span class="fas fa-arrow-alt-circle-down" />
					</b-button>
				</b-col>
			</b-row>
		</b-card>
		<!-- Binary Volume -->
		<b-row
			v-if="!loading && (typeof activeTree !== 'undefined') && !(activeTree.is_spillover || activeTree.is_unauthorized)">
			<b-col class="col-12 col-sm-12 col-md-6 col-lg-6">
				<div class="card h-100">
					<div
						class="card-body p-3">
						<h4
							:class="['xs', 'sm'].includes(windowWidth) ? 'pl-1' : ''"
							class="card-title h5">
							{{ translate('volume_userid_username') }}
							<br v-if="['xs', 'sm'].includes(windowWidth)">
							<div :class="['xs', 'sm'].includes(windowWidth) ? 'pt-1 d-flex align-items-end' : 'd-inline'">
								<small>
									<span
										v-if="admin.includes($user.details().type)"
										class="btn-link exchange-text pointer"
										@click="toDashboard({ distributorId: activeUser.toString() })">
										{{ activeUser }} - {{ activeTree.username }}
									</span>
									<span v-else>
										{{ activeUser }} - {{ activeTree.username }}
									</span>
								</small>
							</div>
						</h4>
						<b-list-group
							v-if="activeUser"
							class="col-12 pr-0">
							<div class="table-responsive">
								<table class="table text-nowrap mb-0">
									<thead>
										<tr class="text-center">
											<th class="align-middle py-2">
												{{ translate('week') }}
											</th>
											<th class="align-middle py-2">
												{{ translate('period') }}
											</th>
											<th class="align-middle py-2">
												{{ translate('left_bv') }}
											</th>
											<th class="align-middle py-2">
												{{ translate('right_bv') }}
											</th>
										</tr>
									</thead>
									<tbody
										v-if="(typeof distributorVolume !== 'undefined' && !loadingVolume)">
										<tr
											v-for="(item) in distributorVolume.weeks"
											:key="item.weeks">
											<td class="align-middle py-2">
												{{ item.week_number }}
											</td>
											<td class="align-middle py-2">
												{{ translate('period_format',{start:$moment(item.start_date.date).format(dateFormat),end:$moment(item.end_date.date).format(dateFormat)}) }}
											</td>
											<td class="align-middle py-2 text-center">
												{{ item.left_bv }}
											</td>
											<td class="align-middle py-2 text-center">
												{{ item.right_bv }}
											</td>
										</tr>
									</tbody>
								</table>
								<small
									v-if="(typeof distributorVolume !== 'undefined' && !loadingVolume) && distributorVolume.show_carryover"
									class="form-text text-muted text-right">
									{{ translate('carryover', {left:distributorVolume.carryover.left,right:distributorVolume.carryover.right}) }}
								</small>
							</div>
						</b-list-group>
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('data_not_found')"
							:loading="loadingVolume"
							:has-data="volumeHasData" />
					</div>
				</div>
			</b-col>
			<b-col
				class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
				<div class="card h-100">
					<div
						v-if="!loadingBinaryInformation"
						class="card-body p-3">
						<div style="display: flex; justify-content: space-between; align-items: start;">
							<h4
								:class="['xs', 'sm'].includes(windowWidth) ? 'pl-1' : ''"
								class="card-title h5"
								style="flex-grow: 1;">
								{{ translate('distributor_info_userid_username') }}
								<b-button
									v-show="$can('unilevel_summary', 'view')"
									v-b-tooltip.hover
									:title="translate('view_unilevel_summary')"
									size="sm"
									variant="primary"
									class="float-right"
									@click="$router.push({ name: 'UnilevelSummaryData', query: { uid: activeUser, page: 1 } })">
									<span class="fas fa-sort-amount-down" />
								</b-button>
								<br v-if="['xs', 'sm'].includes(windowWidth)">
								<div :class="['xs', 'sm'].includes(windowWidth) ? 'pt-1 d-flex align-items-end' : 'd-inline-flex align-items-center'">
									<small>
										<span
											v-if="admin.includes($user.details().type)"
											class="btn-link exchange-text pointer"
											@click="toDashboard({ distributorId: activeUser.toString() })">
											{{ activeUser }} - {{ activeTree.username }}
										</span>
										<span v-else>
											{{ activeUser }} - {{ activeTree.username }}
										</span>
									</small>
									<img
										v-if="distributorInformation.is_rising_star"
										v-b-tooltip.hover
										:title="translate('rising_star')"
										style="vertical-align: text-top;"
										class="pl-1"
										:src="`${S3_PATH}/assets/ranks/${themeName}/rising_star_20x20_green.png`">
									<span
										v-if="activeTree.has_ticket && activeTree.has_enabled_ticket"
										v-b-tooltip.hover
										:title="translate('has_purchased_ticket')"
										style="vertical-align: text-top;margin-left:5px;">
										<i class="fa fa-ticket primary-color" />
									</span>
									<span
										v-if="activeTree.has_ticket && !activeTree.has_enabled_ticket"
										v-b-tooltip.hover
										:title="translate('has_pending_ticket')"
										style="vertical-align: text-top;margin-left:5px;">
										<i class="fa fa-ticket text-muted" />
									</span>
								</div>
							</h4>
						</div>
						<b-list-group
							v-if="activeUser"
							class="col-12 pr-0">
							<b-list-group-item
								v-if="admin.includes($user.details().type)"
								class="py-1">
								<div class="row">
									<div class="col-xs-12 col-sm-6 col-md-5 col-lg-5 font-weight-bold">
										{{ translate('full_name') }}
									</div>
									<div class="col-xs-12 col-sm-6 col-md-7 col-lg-7">
										{{ distributorInformation.full_name }}
									</div>
								</div>
							</b-list-group-item>

							<b-list-group-item
								v-if="distributorInformation.sponsor_id && distributorInformation.sponsor_username || distributorInformation.sponsor_username"
								class="py-1">
								<div class="row">
									<div class="col-xs-12 col-sm-6 col-md-5 col-lg-5 font-weight-bold">
										{{ translate('sponsor') }}
									</div>
									<div
										v-if="distributorInformation.sponsor_id && distributorInformation.sponsor_username"
										class="col-xs-12 col-sm-6 col-md-7 col-lg-7">
										<span
											v-if="admin.includes($user.details().type)"
											class="btn-link exchange-text pointer"
											@click="toDashboard({ distributorId: distributorInformation.sponsor_id.toString() })">
											{{ distributorInformation.sponsor_id }} - {{ distributorInformation.sponsor_username }}
										</span>
										<span v-else>
											{{ distributorInformation.sponsor_id }} - {{ distributorInformation.sponsor_username }}
										</span>
									</div>
									<div
										v-else
										class="col-xs-12 col-sm-6 col-md-7 col-lg-7">
										<span>
											{{ distributorInformation.sponsor_username }}
										</span>
									</div>
								</div>
							</b-list-group-item>

							<b-list-group-item
								v-if="isStaff"
								class="py-1">
								<div class="row">
									<div class="col-xs-12 col-sm-6 col-md-5 col-lg-5 font-weight-bold">
										{{ translate('organization') }}
									</div>
									<div class="col-xs-12 col-sm-6 col-md-7 col-lg-7">
										{{ distributorInformation.organization }}
									</div>
								</div>
							</b-list-group-item>

							<b-list-group-item class="py-1">
								<div class="row">
									<div class="col-xs-12 col-sm-6 col-md-5 col-lg-5 font-weight-bold">
										{{ translate('package') }}
									</div>
									<div class="col-xs-12 col-sm-6 col-md-7 col-lg-7">
										{{ translate(distributorInformation.product_name) }}
									</div>
								</div>
							</b-list-group-item>

							<b-list-group-item class="py-1">
								<div class="row">
									<div class="col-xs-12 col-sm-6 col-md-5 col-lg-5 font-weight-bold">
										{{ translate('permanent_rank') }}
									</div>
									<div class="col-xs-12 col-sm-6 col-md-7 col-lg-7 d-flex align-items-center">
										{{ translate(distributorInformation.permanent_rank) }}
										<template v-if="subRankIndicator(distributorInformation, 'permanent_sub_rank') !== null">
											<img
												:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(distributorInformation, 'permanent_sub_rank')}_indicator.png`"
												width="15px"
												class="ml-1"
												style="margin-bottom: 2px"
												alt="sub_rank">
										</template>
									</div>
								</div>
							</b-list-group-item>

							<b-list-group-item class="py-1">
								<div class="row">
									<div class="col-xs-12 col-sm-6 col-md-5 col-lg-5">
										<span class="font-weight-bold">{{ translate('rank') }}</span> ({{ translate('week_number', {number: distributorInformation.rank_week}) }})
									</div>
									<div class="col-xs-12 col-sm-6 col-md-7 col-lg-7 d-flex align-items-center">
										{{ translate(distributorInformation.rank) }}
										<template v-if="subRankIndicator(distributorInformation, 'current_sub_rank') !== null">
											<img
												:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(distributorInformation, 'current_sub_rank')}_indicator.png`"
												width="15px"
												class="ml-1"
												style="margin-bottom: 2px"
												alt="sub_rank">
										</template>
									</div>
								</div>
							</b-list-group-item>

							<b-list-group-item class="py-1">
								<div class="row">
									<div class="col-xs-12 col-sm-6 col-md-5 col-lg-5 font-weight-bold">
										{{ translate('is_qualified') }}
									</div>
									<div class="col-xs-12 col-sm-6 col-md-7 col-lg-7">
										{{ translate(`${!!distributorInformation.qualified}`) }}
									</div>
								</div>
							</b-list-group-item>

							<b-list-group-item class="py-1">
								<div class="row">
									<div class="col-xs-12 col-sm-6 col-md-5 col-lg-5 font-weight-bold">
										{{ translate('status') }}
									</div>
									<div class="col-xs-12 col-sm-6 col-md-7 col-lg-7">
										{{ translate(activeTree.status) }}
										<span v-if="!finalStatus.includes(activeTree.status) && distributorInformation.active_until !== null && ($moment(distributorInformation.active_until.date) >= $moment().tz(distributorInformation.active_until.timezone))">
											({{ translate('date_period_until', { end: distributorInformation.active_until ? $moment(distributorInformation.active_until.date).format(dateFormatYear) : distributorInformation.active_until }) }})
										</span>
									</div>
								</div>
							</b-list-group-item>

							<b-list-group-item
								v-if="distributorInformation.active_until !== null && ($moment(distributorInformation.active_until.date) >= $moment().tz(distributorInformation.active_until.timezone) && distributorInformation.user_has_autoship === true)"
								class="py-1">
								<div class="row">
									<div class="col-xs-12 col-sm-6 col-md-5 col-lg-5 font-weight-bold">
										{{ translate('next_autoship') }}
									</div>
									<div class="col-xs-12 col-sm-6 col-md-7 col-lg-7">
										{{ distributorInformation.next_autoship ? $moment(distributorInformation.next_autoship.date).format(dateFormatYear) : translate('no_attempts_remaining') }}
									</div>
								</div>
							</b-list-group-item>
						</b-list-group>
					</div>
					<is-loading
						v-else
						class="d-flex justify-content-center align-items-center h-100"
						:loading-label="translate('loading')"
						:loading="loadingBinaryInformation" />
				</div>
			</b-col>
		</b-row>
		<!-- Loading -->
		<is-loading
			v-if="isCountryCorporate ? this.$route.params.userId : true"
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />

		<!-- Rank Progress -->
		<!-- <b-row v-if="!loading && typeof activeTree != 'undefined'">
			<b-col>
				<rank-progress :user-id="activeUser" />
			</b-col>
		</b-row> -->
	</div>
</template>
<script>
import Vue from 'vue';
import { SEARCH_USERS } from '@/config/endpoint';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import WindowSizes from '@/mixins/WindowSizes';
import FiltersParams from '@/mixins/FiltersParams';
import SubRanks from '@/mixins/SubRanks';
import { MD_FORMAT, MDY_FORMAT } from '@/settings/Dates';
import { admin, corporate } from '@/settings/Roles';
import {
	SYMBOLISM as accountStatus, AUTOSHIP_FAILED as autoshipFailed, NO_EDITABLE_STATUSES as finalStatus, DISTRIBUTOR_HIDDEN_STATUSES,
} from '@/settings/Statuses';
import {
	Alerts, Network, Users, Ranks, Packages, Grids, Events,
} from '@/translations';
import BinaryTree from '@/util/BinaryTree';
// import RankProgress from '@/views/Dashboard/components/RankProgress';
import GraphicTree from './components/GraphicTree';
import BinaryTreeItem from './components/BinaryTreeItem';
import TreeMixin from './mixins/Tree';
import { S3_PATH } from '@/settings/Images';

const { endpoint } = SEARCH_USERS;

Vue.component('child-binary', BinaryTreeItem);
Vue.component('graphic-tree', GraphicTree);

export default {
	name: 'GenealogyTree',
	// components: { RankProgress },
	mixins: [WindowSizes, FiltersParams, TreeMixin, DashboardRedirect, SubRanks],
	messages: [Alerts, Network, Users, Ranks, Packages, Grids, Events],
	data() {
		return {
			admin,
			accountStatus,
			activeUser: null,
			dateFormat: MD_FORMAT,
			dateFormatYear: MDY_FORMAT,
			endpoint,
			hoverTreeInfo: {},
			myOuterNodes: new BinaryTree(),
			myTree: new BinaryTree(),
			binaryVolume: new BinaryTree(),
			binaryInformation: new BinaryTree(),
			selectedUser: '',
			autoshipFailed,
			themeName: process.env.VUE_APP_THEME,
			S3_PATH,
			finalStatus,
			distributorHiddenStatuses: DISTRIBUTOR_HIDDEN_STATUSES,
		};
	},
	computed: {
		loading() {
			return !!this.myTree.data.loading;
		},
		loadingVolume() {
			return !!this.binaryVolume.volumeData.loading;
		},
		loadingBinaryInformation() {
			return !!this.binaryInformation.data.loading;
		},
		tree() {
			try {
				const [tree] = this.myTree.data.response.data.data.attributes;
				if (typeof tree === 'object') {
					return tree;
				}
				return {};
			} catch (error) {
				return {};
			}
		},
		rootId() {
			try {
				const { id } = this.myTree.data.response.data.data;
				if (id) {
					return id;
				}
				return '';
			} catch (error) {
				return '';
			}
		},
		outerNodes() {
			try {
				const { response } = this.myOuterNodes.outerData.response.data;
				return response;
			} catch (error) {
				return [];
			}
		},
		distributorVolume() {
			try {
				return this.binaryVolume.volumeData.response.data;
			} catch (error) {
				return [];
			}
		},
		distributorInformation() {
			try {
				return this.binaryInformation.data.response.data.response;
			} catch (error) {
				return '';
			}
		},
		hasData() {
			return !!Object.keys(this.tree).length;
		},
		volumeHasData() {
			return !!Object.keys(this.binaryVolume.volumeData).length;
		},
		childRows() {
			return this.windowWidth === 'lg' || this.windowWidth === 'xl' ? 4 : 3;
		},
		activeClass() {
			return this.activeUser === this.tree.distributor_id;
		},
		hasOuterNodeLeft() {
			return !!(this.tree.distributor_id === Number(this.outerNodes.left_outer_distributor) || typeof this.outerNodes.left_outer_distributor === 'undefined');
		},
		hasOuterNodeRight() {
			return !!(this.tree.distributor_id === Number(this.outerNodes.right_outer_distributor) || typeof this.outerNodes.right_outer_distributor === 'undefined');
		},
		isGraphic() {
			return this.$route.query.type === 'graphic';
		},
		activeTree() {
			return this.getTreeById(this.activeUser);
		},
		getLoadedUser() {
			const id = parseInt(this.$route.params.userId, 10);
			if (!Number.isNaN(id)) {
				return String(id);
			}
			return undefined;
		},
		username() {
			try {
				return this.myTree.data.response.data.meta.username;
			} catch (error) {
				return '';
			}
		},
		isStaff() {
			return this.admin.includes(this.$user.details().type);
		},
		isCountryCorporate() {
			const isCorporate = this.$user.details().type === corporate;
			const hasCountry = typeof this.$user.details().country !== 'undefined';

			return isCorporate && hasCountry;
		},
	},
	mounted() {
		const load = this.isCountryCorporate ? this.$route.params.userId : true;
		if (load) {
			this.getDataFiltered(Number(this.childRows), this.$route.params.userId);
		}
	},
	methods: {
		setItemActive(id) {
			let userId = id;
			if (typeof userId !== 'number') {
				userId = this.tree.distributor_id;
			}
			if (this.activeUser !== userId) {
				this.activeUser = userId;
				this.binaryVolume.getBinaryTreeVolume({ id: this.activeUser });
				this.binaryInformation.getBinaryTreeInformation({ id: this.activeUser });
			}
		},
		getTreeById(id) {
			const findItem = (tree) => {
				let response = null;
				if (tree instanceof Array) {
					for (let i = tree.length - 1; i >= 0; i -= 1) {
						response = findItem(tree[i]);
						if (typeof response !== 'undefined') {
							return response;
						}
					}
				} else if (typeof tree === 'object') {
					if (tree.distributor_id === id) {
						return tree;
					}
					response = findItem(tree.children);
					if (typeof response !== 'undefined') {
						return response;
					}
				}

				return undefined;
			};

			const tree = findItem(this.tree);

			return (typeof tree === 'object') ? tree : undefined;
		},
		getDataFiltered(distance, id) {
			const filters = { distance, id };
			this.myTree.getTree(filters).then(() => {
				if (this.tree.distributor_id) {
					this.myOuterNodes.getOuterNodes({ id: this.tree.distributor_id });
					this.setItemActive(this.rootId);
				}
			});
		},
		registerInPosition(payload) {
			this.registerCountry = this.country;
			this.setRegisterCountry(this.country);
			this.setRegisterPositionValues(payload);
			this.$forceUpdate();
			this.$store.dispatch('position/registerInPosition', payload).then(() => {
				this.$router.push({ name: 'Register' });
			});
		},
		handleTreeType(type) {
			const params = { userId: this.$route.params.userId ? this.$route.params.userId : null };
			this.$router.replace({ name: this.$route.params.name, params, query: { type } }).catch(() => {});
		},
	},
};
</script>
<style>
	.tree-search-bar > div{
		width: 79%;
		display: inline-block;
	}
	.tree-search-bar button{
		width: 19%;
		display: inline-block;
		margin: -4px 0 0 -5px;
		position: relative;
		z-index: 2;
	}
	@media (max-width: 499px) {
		.tree-search-bar > div, .tree-search-bar button {
			width: 100%;
			margin: 0;
		}
	}
	.indicator-color{
		color: #000000;
	}
	#showSearch label{
		display: none;
	}
	#showSearch .form-group.relative {
		margin-bottom: 0px;
	}
	.tree-card .card-body{
		padding-top:.5em;
		padding-bottom:.5em;
	}
	.col-custom {
		width: 48%
	}
</style>
