<template>
	<div class="w-50">
		<div
			v-if="hasData"
			:class="{'bg-secondary': activeClass}"
			:style="!['xs','sm'].includes(windowWidth) ? 'height: 55px;' : ''"
			class="border tree-item d-flex pointer"
			@mouseenter="setItemActive"
			@click="changeUser(tree.distributor_id)">
			<strong class="d-block text-gray-dark m-auto text-truncate">
				<i
					v-if="!(tree.is_spillover || tree.is_unauthorized)"
					:class="findColor(tree.status.toLowerCase())"
					class="mr-1" />
				<template v-if="!!tree.username">
					{{ tree.username }}
				</template>
				<template v-if="tree.is_spillover">
					{{ translate('spillover').toUpperCase() }}
				</template>
				<template v-if="tree.is_unauthorized">
					{{ translate('unauthorized').toUpperCase() }}
				</template>
				<i
					v-if="tree.autoship_failed"
					:class="`${ autoshipFailed.icon } ${ autoshipFailed.color }`"
					class="mr-1" />
				<span
					v-if="tree.has_ticket && tree.has_enabled_ticket"
					v-b-tooltip.hover
					:title="translate('has_purchased_ticket')"
					style="vertical-align: text-top;margin-left:5px;">
					<i class="fa fa-ticket primary-color" />
				</span>
				<span
					v-if="tree.has_ticket && !tree.has_enabled_ticket"
					v-b-tooltip.hover
					:title="translate('has_pending_ticket')"
					style="vertical-align: text-top;margin-left:5px;">
					<i class="fa fa-ticket text-muted" />
				</span>
				<br>
				<span class="font-weight-light">({{ tree.is_spillover || tree.is_unauthorized ? translate('NA') : tree.distributor_id }})</span>
			</strong>
		</div>
		<div
			v-else
			:class="{'bg-secondary': activeClass}"
			:style="!['xs','sm'].includes(windowWidth) ? 'height: 55px;' : ''"
			class="border tree-item d-flex">
			<template v-if="openPosition !== '' && $user.details().type === distributor">
				<b-button
					v-if="isCurrentUserActive"
					v-b-tooltip.hover
					:title="['xs', 'sm'].includes(windowWidth) ? openPosition : ''"
					variant="primary"
					class="m-auto"
					@click="$emit('registerInPosition', { parent: $parent.tree.distributor_id, placement, spillover: $parent.tree.is_spillover })">
					<span v-if="['xs', 'sm'].includes(windowWidth)">
						<i class="fas fa-plus" />
					</span>
					<span v-else>
						{{ openPosition }}
					</span>
				</b-button>
				<b-button
					v-else
					v-b-tooltip.hover.html="translate('your_account_is_inactive_link', { points: pointsToActivate, link: $router.resolve({ name: 'MainStore' }).href })"
					variant="secondary"
					class="m-auto">
					<span v-if="['xs', 'sm'].includes(windowWidth)">
						<i class="fas fa-plus" />
					</span>
					<span v-else>
						{{ openPosition }}
					</span>
				</b-button>
			</template>
		</div>
		<div class="tree-level">
			<child-binary
				v-if="deep > 1"
				:active-user="activeUser"
				:tree="childItem('l')"
				:deep="deep-1"
				placement="left"
				@registerInPosition="registerInPosition" />
			<child-binary
				v-if="deep > 1"
				:active-user="activeUser"
				:tree="childItem('r')"
				:deep="deep-1"
				placement="right"
				@registerInPosition="registerInPosition" />
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { ROWS } from '@/settings/Tree';
import { distributor } from '@/settings/Roles';
import { AUTOSHIP_FAILED as autoshipFailed, STATUSES } from '@/settings/Statuses';
import { Network, Users, Events } from '@/translations';
import TreeMixin from '../mixins/Tree';
import { POINTS_TO_ACTIVATE } from '@/settings/Points';

export default {
	name: 'BinaryTreeItem',
	messages: [Network, Users, Events],
	mixins: [WindowSizes, TreeMixin],
	props: {
		activeUser: {
			type: Number,
			default: null,
		},
		tree: {
			type: [Object, Boolean],
			default() {
				return false;
			},
		},
		deep: {
			type: Number,
			default: null,
		},
		placement: {
			required: true,
			type: String,
			validator: (value) => ['left', 'right'].includes(value),
		},
	},
	data() {
		return {
			autoshipFailed,
			distributor,
			pointsToActivate: POINTS_TO_ACTIVATE,
		};
	},
	computed: {
		activeClass() {
			return this.activeUser === this.tree.distributor_id;
		},
		hasData() {
			return !!Object.keys(this.tree).length;
		},
		openPosition() {
			let rows = ROWS[this.windowWidth];
			if (typeof rows === 'undefined') {
				rows = ROWS.default;
			}

			if (this.deep === rows) {
				return this.translate('add_here');
			}

			if (typeof this.$parent.$props !== 'undefined' && this.$parent.$props.tree) {
				return this.translate('add_here');
			}
			return '';
		},
		isCurrentUserActive() {
			return this.$user.details().status === STATUSES.active;
		},
	},
	methods: {
		setItemActive(userid) {
			let id = this.tree.distributor_id;
			if (typeof userid === 'number') {
				id = userid;
			}
			this.$parent.setItemActive(id);
		},
	},
};
</script>
<style>
	.tree-item{
		height: 100px;
	}
	.tree-level{
		display: flex;
		flex-wrap: wrap;
		margin-right: 0px;
		margin-right: 0px;
	}
</style>
