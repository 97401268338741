import { COLORS } from '@/settings/Statuses';
import { PLACEMENTS, TREE_TYPES } from '@/settings/Tree';

export default {
	props: {
		treeType: {
			type: String,
			default: null,
			validator: (value) => !value || Object.values(TREE_TYPES).includes(value),
		},
	},
	mounted() {
		this.$parent.titleParam = { user: this.username };
	},
	watch: {
		username: {
			handler(user) {
				if (this.treeType === TREE_TYPES.BINARY) {
					this.$parent.titleKey = user ? 'BinaryTree' : 'BinaryTreeAlt';
				}
				this.$parent.titleParam = { user };
			},
			immediate: true,
		},
	},
	methods: {
		findColor(status) {
			const accountType = COLORS.find((account) => account.statusCode === status);
			return `${accountType.icon} ${accountType.color}`;
		},
		changeUser(id) {
			const userId = parseInt(id, 10);
			const { name, query, params } = this.$route;
			delete params.userId;
			if (!Number.isNaN(userId)) {
				params.userId = userId;
			}
			this.$router.replace({ name, params, query }).catch(() => {});
		},
		childItem(position) {
			const pos = PLACEMENTS[position.toLowerCase()];
			if (!this.tree.children || typeof pos === 'undefined') {
				return false;
			}
			const [response] = this.tree.children.filter((item) => ((item.binary_placement === pos) ? item : false));
			return response;
		},
		registerInPosition(payload) {
			const { parent, placement, spillover } = payload;
			if (typeof parent === 'undefined' || typeof placement === 'undefined') {
				return null;
			}
			this.$emit('registerInPosition', { parent, placement, spillover });
			return null;
		},
	},
};
