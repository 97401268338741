<template>
	<div class="detail-tree-level">
		<div
			v-if="hasData"
			class="tree-user">
			<div class="tree-left-side">
				<div class="tree-image-content">
					<img
						id="avatar"
						:src="tree.profile_photo ? tree.profile_photo : defaultProfileImage"
						class="tree-image">
					<div
						:style="tree ? 'cursor:pointer' : false"
						class="tree-user-header"
						@mouseover="setItemActive"
						@click="changeUser(tree.distributor_id)">
						<i
							v-if="!(tree.is_spillover || tree.is_unauthorized)"
							:class="findColor(tree.status.toLowerCase())"
							class="fa fa-user mr-1" /> <template v-if="!!tree.username">
								{{ tree.username }}
							</template>
						<template v-if="tree.is_spillover">
							{{ translate('spillover').toUpperCase() }}
						</template>
						<template v-if="tree.is_unauthorized">
							{{ translate('unauthorized').toUpperCase() }}
						</template>
					</div>
				</div>
			</div>
			<div class="tree-right-side">
				<div class="tree-user-body">
					<table class="tree-user-table">
						<tbody>
							<tr
								v-if="admin.includes($user.details().type) && !tree.is_unauthorized">
								<td class="tree-cell-bold">
									{{ translate('name') }}
								</td>
								<td class="tree-cell-light">
									{{ tree.full_name }}
								</td>
							</tr>
							<tr>
								<td class="tree-cell-bold">
									{{ translate('id_word') }}
								</td>
								<td class="tree-cell-light">
									{{ tree.is_spillover || tree.is_unauthorized ? translate('NA') : tree.distributor_id }}
								</td>
							</tr>
							<tr>
								<td class="tree-cell-bold">
									{{ translate('package') }}
								</td>
								<td class="tree-cell-light">
									{{ tree.is_spillover || tree.is_unauthorized ? translate('NA') : translate(tree.product_name) }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<template v-else-if="$user.details().type === distributor">
			<div
				v-if="isCurrentUserActive"
				class="tree-user tree-open text-center text-white pointer"
				@click="$emit('registerInPosition',{ parent: $parent.tree.distributor_id, placement, spillover: $parent.tree.is_spillover })">
				{{ translate('add_here') }}
			</div>
			<div
				v-else
				v-b-tooltip.hover.html="translate('your_account_is_inactive_link', { points: pointsToActivate, link: $router.resolve({ name: 'MainStore' }).href })"
				class="tree-user tree-open bg-gray text-center text-white">
				{{ translate('add_here') }}
			</div>
		</template>
		<template v-if="deep && tree">
			<div class="start-line" />
			<div class="lines-content">
				<div class="col lines-left" />
				<div class="col lines-right" />
			</div>
			<div class="child-content">
				<div class="child-item">
					<graphic-tree
						:tree="childItem('l')"
						:deep="deep-1"
						placement="left"
						@registerInPosition="registerInPosition" />
				</div>
				<div class="child-item">
					<graphic-tree
						:tree="childItem('r')"
						:deep="deep-1"
						placement="right"
						@registerInPosition="registerInPosition" />
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import { admin, distributor } from '@/settings/Roles';
import { STATUSES } from '@/settings/Statuses';
import { Network, Users, Packages } from '@/translations';
import TreeMixin from '../mixins/Tree';
import { POINTS_TO_ACTIVATE } from '@/settings/Points';

const defaultProfileImage = require('@/assets/images/common/profile.png');

export default {
	name: 'GraphicTree',
	messages: [Network, Users, Packages],
	mixins: [TreeMixin],
	props: {
		tree: {
			type: [Object, Boolean],
			default() {
				return false;
			},
		},
		deep: {
			type: Number,
			default: null,
		},
		placement: {
			required: true,
			type: String,
			validator: (value) => ['left', 'right'].includes(value),
		},
	},
	data() {
		return {
			admin,
			defaultProfileImage,
			distributor,
			pointsToActivate: POINTS_TO_ACTIVATE,
		};
	},
	computed: {
		hasData() {
			return !!Object.keys(this.tree).length;
		},
		isGraphic() {
			return this.$route.query.type === 'graphic';
		},
		isCurrentUserActive() {
			return this.$user.details().status === STATUSES.active;
		},
	},
	methods: {
		setItemActive(userid) {
			let id = this.tree.distributor_id;
			if (typeof userid === 'number') {
				id = userid;
			}
			this.$parent.setItemActive(id);
		},
	},
};
</script>
<style>
	.child-content{
		width:100%;
		display: flex;
	}
	.child-item {
		float: left;
		margin: 0;
		width: 50%;
	}
	.start-line {
		border-right: 2px solid #BBBBBB;
		height: 15px;
		width: 50%;
	}
	.lines-left {
		margin: 0px;
		padding: 0px;
		border-left: 2px solid #BBBBBB;
		border-top: 2px solid #BBBBBB;
		height: 15px;
		float: left;
		width:25% !important;
	}
	.lines-content {
		width:100%;
		margin-left: 25%;
	}
	.lines-right {
		margin: 0px;
		padding: 0px;
		border-right: 2px solid #BBBBBB;
		border-top: 2px solid #BBBBBB;
		height: 15px;
		float: left;
		width:25% !important;
	}
	.tree-cell-bold {
		font-weight: bold;
		display: flex;
		margin: 2px;
	}
	.tree-image {
		width: 28px;
		height: 28px;
		border-radius: 33px;
	}
	.tree-image-content {
		border-radius: 35px 0px 0px 35px;
		padding: 2px;
	}
	.tree-left-side {
		position: static;
		margin-left: 0px;
		padding: 0px;
	}
	.tree-right-side {
		margin-left: 30px;
		background: #f0f3f5;
	}
	.tree-user {
		width:150px;
		display: block;
		margin-right: auto;
		margin-left: auto
	}
	.tree-user-body {
		background: #f0f3f5;
		margin: 0;
		padding: 5px;
		font-size: 9px;
		line-height: 9px;
	}
	.tree-user-header {
		color: #fff;
		font-weight: bold;
		text-align: center;
		height: 30px;
		padding: 0 5px;
		line-height: 30px;
		text-align: center;
		font-size: 10px;
		width: 80%;
		display: inline-block;
		float: right;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.tree-user-table {
		width:100%;
	}
	.tree-open {
		width: 120px;
		margin: 0 auto !important;
		font-size: 10px;
		font-weight: bold;
		line-height: 30px;
		padding: 0 !important;
	}

	@media (min-width: 1400px) and (max-width: 1574px) {
		.tree-user {
			width: 125px;
		}
		.tree-user-header{
			width: 75%;
		}
		.tree-right-side {
			margin-left: 15px;
		}
	}
	@media (min-width: 1300px) and (max-width: 1399px) {
		.tree-user {
			width: 115px;
		}
		.tree-user-header{
			width: 72%;
		}
		.tree-right-side {
			margin-left: 15px;
		}
	}
	@media (max-width: 1299px) {
		.detail-tree-level .detail-tree-level .detail-tree-level .start-line, .detail-tree-level .detail-tree-level .detail-tree-level .lines-content, .detail-tree-level .detail-tree-level .detail-tree-level .child-content{
			display: none;
		}
	}
	@media (max-width: 699px) {
		.tree-user {
			width: 125px;
		}
		.tree-user-header{
			width: 75%;
		}
		.tree-right-side {
			margin-left: 14px;
		}
		.tree-right-side tr {
			padding-bottom: 3px !important;
			display: block;
		}
		.tree-right-side td{
			width: 100%;
			display: block;
			padding: 0;
			margin: 0 0 3px;
		}
	}
	@media (max-width: 599px) {
		.tree-user {
			width: 80px;
		}
		.tree-image-content{
			border-radius: 0;
		}
		.tree-image {
			width: 20px;
			height: 20px;
			margin: 4px 0 4px 2px;
		}
		.tree-user-header {
			text-align: left;
			padding: 0 5px 0 0;
			width: 69%;
		}
		.tree-right-side {
			margin-left: 0;
		}
	}
	@media (max-width: 599px) {
		.tree-user {
			width: 75px;
		}
		.tree-image-content{
			border-radius: 0;
		}
		.tree-image {
			width: 20px;
			height: 20px;
			margin: 4px 0 4px 2px;
		}
		.tree-user-header {
			text-align: left;
			padding: 0 5px 0 0;
			width: 68%;
		}
		.tree-right-side {
			margin-left: 0;
		}
	}
	@media (max-width: 409px) {
		.detail-tree-level .detail-tree-level .start-line, .detail-tree-level .detail-tree-level .lines-content, .detail-tree-level .detail-tree-level .child-content{
			display: none;
		}
		.tree-user {
			width: 110px;
		}
	}
</style>
